export enum PageErrorType {
  // api errors
  // 400
  BadRequest = "BadRequest",
  // 403
  AnalysisInsufficientPermission = "AnalysisInsufficientPermission",
  GeneralInsufficientPermission = "GeneralInsufficientPermission",
  VariableMappingInsufficientPermission = "VariableMappingInsufficientPermission",
  CustomModelInsufficientPermission = "CustomModelInsufficientPermission",
  // 404
  AnalysisNotFound = "AnalysisNotFound",
  TopicNotFound = "TopicNotFound",
  // 500
  UnexpectedServerError = "UnexpectedServerError",
  UnexpectedPrintError = "UnexpectedPrintError",
  // other error types
  AnalysisNotAnalyzed = "AnalysisNotAnalyzed",
  AnalysisFailed = "AnalysisFailed",
  RedactionFeatureNotActivated = "RedactionFeatureNotActivated",
  LLMSettingNotActive = "LLMSettingNotActive",
  NoUsersSelected = "NoUsersSelected",
  Other = "Other",
}
