import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { fetchSummaryOverview } from "store/summary/thunks";
import { clearState, setIsLoadingAnimationActive } from "store/summary/summarySlice";
import { useQueryParams } from "hooks/useQueryParams";

import styled, { css } from "styled-components";
import nlpLoading from "assets/animations/nlp-loading.webm";
import nlpLoadingMac from "assets/animations/nlp-loading-mac.mov";

import { MostCriticalInsights } from "./MostCriticalInsights";
import { Text } from "components/Text";
import { TextTruncator } from "components/TextTruncator";
import { AnalysisContext } from "context/AnalysisContext";
import { FRAME_TRANSITION } from "components/_screens/WelcomeScreen/constants";
import { AnimatedText } from "components/AnimatedText";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { SharingSummaryAccess, WidgetType } from "@explorance/mly-types";

import { SystemOS, getSystemOs } from "utils/getSystemOS";
import { ErrorScreen } from "components/ErrorScreen";
import { PageErrorType } from "ts/enums/pageErrorType";
import axios, { CancelTokenSource } from "axios";

const INSIGHT_ORDER: readonly WidgetType[] = [
  WidgetType.PrioritizedRecommendations,
  WidgetType.WhatToImprove,
  WidgetType.MostPositive,
];

export const SummaryPage = ({ printMode = false }) => {
  const state = useAppSelector((state) => state.summary);
  const { features } = useAppSelector((state) => state.settings);
  const { currentUser } = useAppSelector((state) => state.auth);
  const [analysisContextState] = useContext(AnalysisContext);
  const systemOS = getSystemOs();
  const dispatch = useAppDispatch();
  const { sharingPreview, sharingId } = useQueryParams();
  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  // In print mode, we don't have the routing to get the analysisId from the params, so we need to get it from the pathname
  const analysisIdPrintMode = parseInt(useLocation().pathname.split("/").at(2));
  const isSharing = !!analysisContextState.analysisDetails.sharing;
  const hasPageAccess =
    printMode ||
    !isSharing ||
    analysisContextState.analysisDetails.sharing.summaryAccess === SharingSummaryAccess.Shared;

  const sortedInsights = useMemo(
    () =>
      state.summaryOverview
        ? Object.keys(state.summaryOverview)
            .filter((key) => INSIGHT_ORDER.includes(key as WidgetType))
            .sort(
              (a, b) =>
                INSIGHT_ORDER.indexOf(a as WidgetType) - INSIGHT_ORDER.indexOf(b as WidgetType)
            )
        : null,
    [state.summaryOverview]
  );

  const handlePrintAction = () => {
    if (printMode) {
      window.print();
    } else {
      const newWindow = window.open(
        `/analysis/${analysisId}/print-summary`,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  useEffect(() => {
    cancelTokenSourceRef.current = axios.CancelToken.source();
    dispatch(
      fetchSummaryOverview({
        analysisId: printMode ? analysisIdPrintMode : analysisId,
        cancelToken: cancelTokenSourceRef.current.token,
        user_id: analysisContextState.previewUser.id,
        ...(sharingId && { sharing_id: parseInt(sharingId) }),
      })
    );

    const timeoutId = setTimeout(() => {
      dispatch(setIsLoadingAnimationActive(false));
    }, 2500);

    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel("Summary page unmounted");
      }
      dispatch(clearState());
      clearTimeout(timeoutId);
    };
  }, [
    printMode,
    analysisIdPrintMode,
    analysisId,
    cancelTokenSourceRef,
    analysisContextState.previewUser,
    sharingId,
    dispatch,
  ]);

  if (!features.llm) return <ErrorScreen errorType={PageErrorType.LLMSettingNotActive} />;

  if (!hasPageAccess || state.errorType === PageErrorType.AnalysisInsufficientPermission) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  } else if (state.errorType) {
    return (
      <ErrorScreen errorType={printMode ? PageErrorType.UnexpectedPrintError : state.errorType} />
    );
  }

  return (
    <StyledSummaryContainer printMode={printMode} className="fade-in">
      <StyledHeader>
        <StyledNamePrint>
          <StyledUserName>
            {((analysisContextState.previewUser.firstname && sharingPreview) ||
              !sharingPreview) && (
              <TextTruncator
                value={
                  <Text
                    resource={{
                      key: "summary.header.userName.title",
                      args: [
                        `${
                          sharingPreview
                            ? analysisContextState.previewUser.firstname
                            : currentUser.firstname
                        }`,
                      ],
                    }}
                  />
                }
                customWidth="420px"
              />
            )}
          </StyledUserName>
          <StyledPrintButton>
            {!state.isLoading && (
              <Button
                variant={ButtonVariant.primary}
                size={ButtonSize.sm}
                onClick={() => handlePrintAction()}
                disabled={!!sharingPreview}
              >
                {printMode ? (
                  <>
                    <Icon
                      type={IconType.print}
                      size={16}
                      color={Color.blue50}
                      style={{ marginRight: "6px" }}
                    />
                    <Text resource="button.print" />
                  </>
                ) : (
                  <>
                    <Text resource="button.printableVersion" />
                    <Icon
                      type={IconType.externalLink}
                      size={16}
                      color={Color.blue50}
                      style={{ marginLeft: "6px" }}
                    />
                  </>
                )}
              </Button>
            )}
          </StyledPrintButton>
        </StyledNamePrint>
        <StyledHeaderCaption>
          <Text resource="summary.header.caption" />
        </StyledHeaderCaption>
      </StyledHeader>

      <StyledBodyContainer printMode={printMode}>
        {state.isLoading || (state.isLoadingAnimationActive && !state.isCachedResponse) ? (
          <StyledAnimationContainer>
            <video autoPlay loop muted playsInline aria-label="MLY LOADING">
              <source
                src={systemOS === SystemOS.Mac ? nlpLoadingMac : nlpLoading}
                type="video/webm"
              />
            </video>
            <StyledAnimationTextContainer>
              <StyledHeaderText>
                <Text resource="summary.animation.header" />
                <span />
              </StyledHeaderText>
              <StyledDescriptionText>
                <Text resource="summary.animation.description" />
              </StyledDescriptionText>
            </StyledAnimationTextContainer>
          </StyledAnimationContainer>
        ) : (
          <>
            <StyledSummary>
              <AnimatedText
                text={state.summaryOverview.sections.reduce(
                  (acc, section, index) =>
                    acc +
                    section.content +
                    (index !== state.summaryOverview.sections.length - 1 ? "\n\n" : ""),
                  ""
                )}
                speed={state.isCachedResponse ? 0 : 25}
              />
            </StyledSummary>
            {(state.isSummaryAnimationComplete || state.isCachedResponse) && (
              <>
                {state.summaryOverview?.MostAlerts && (
                  <>
                    <StyledLine />
                    <StyledAlertBlock>
                      <StyledAlertTitleBlock>
                        <Icon type={IconType.alertNoBgColor} size={16} />
                        <StyledAlertTitleContainer>
                          <Text resource="summary.alerts.title" />
                        </StyledAlertTitleContainer>
                      </StyledAlertTitleBlock>
                      <StyledAlertContent>
                        {state.summaryOverview?.MostAlerts[0].content}
                      </StyledAlertContent>
                    </StyledAlertBlock>
                  </>
                )}
                {sortedInsights.map((insight, index) => (
                  <StyledInsightBlock key={index}>
                    <MostCriticalInsights fadeIn={!state.isCachedResponse} insight={insight} />
                  </StyledInsightBlock>
                ))}
              </>
            )}
          </>
        )}
      </StyledBodyContainer>
    </StyledSummaryContainer>
  );
};

const StyledInsightBlock = styled.div`
  margin-top: 24px;
  @media print {
    break-inside: avoid;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 24px;
`;

const StyledNamePrint = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-start;
  gap: 6px;
`;

const StyledPrintButton = styled.div`
  @media print {
    display: none;
  }
  align-self: flex-end;
  flex-shrink: 1;
`;

const StyledUserName = styled.div`
  flex-grow: 2;
  font-weight: bold;
  font-size: 32px;
  color: ${Color.indigo60};
`;

const StyledHeaderCaption = styled.div`
  color: ${Color.gray40};
  font-size: 32px;
`;

const StyledSummaryContainer = styled.div<{ printMode: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${({ printMode }) =>
    printMode &&
    css`
      margin: 0 auto;
      padding-top: 40px;
      width: 50%;
      min-width: 500px;
    `};

  @media print {
    padding: 40px;
    width: 100%;
  }
`;

const StyledBodyContainer = styled.div<{ printMode: boolean }>`
  background-color: ${Color.white};
  border-radius: 10px;
  ${({ printMode }) =>
    !printMode &&
    css`
      padding: 32px 40px;
    `};
`;

const StyledAnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  max-height: 500px;

  video {
    width: 210px;
    height: 100%;
    max-height: 100%;
    transition: opacity ${FRAME_TRANSITION};
  }
`;

const StyledAnimationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledHeaderText = styled.div`
  display: flex;
  margin-left: 12px;
  font-weight: bold;
  font-size: 24px;
  padding: 8px;

  span:before {
    animation: dots 2s linear infinite;
    content: "";

    @keyframes dots {
      0%,
      20% {
        content: ".";
      }
      40% {
        content: "..";
      }
      60% {
        content: "...";
      }
      90%,
      100% {
        content: "";
      }
    }
  }
`;

const StyledDescriptionText = styled.div`
  display: flex;
  text-align: center;
`;

const StyledSummary = styled.div`
  color: ${Color.gray50};
`;

const StyledLine = styled.div`
  width: 100%;
  margin-top: 24px;
  background: ${Color.indigo20};
  height: 1px;
`;

const StyledAlertBlock = styled.div`
  border-radius: 12px;
  padding: 12px;
  margin-top: 24px;
  background-color: ${Color.red10};
  width: 100%;
  color: ${Color.gray50};
  @media print {
    border: 1px solid ${Color.indigo40};
  }
  > :first-child {
    padding-top: 2px;
  }
`;

const StyledAlertTitleBlock = styled.div`
  display: flex;
  gap: 6px;
`;

const StyledAlertTitleContainer = styled.div`
  font-weight: bold;
`;

const StyledAlertContent = styled.div`
  padding: 6px 24px;
`;
